/*공통*/
html {
	font-size: clamp(13px, calc(16 * (100vw / 1024)), 16px);
}
.renew_background {
	background-color: #FAFAF8;
	font-style: normal;
	line-height: normal;
	padding: 3.13rem;
}
.renew_background.header {
	display: flex;
	justify-content: center;
	border-bottom: 1px solid #F3ECE0;
	padding: 0rem 3.12rem;
	position: fixed;
	width: 100%;
	z-index: 10;
}
.renew_background.drawvideo {
	padding: 0;
	width: 100%;
	/* 추가한거 */
	/* padding-top: 3rem; */
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;
}
.renew_background.notice {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.renew_background.signup {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.content_align {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.content_align.header {
	height: 5rem;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
}
.content_maxwidth {
	width: 100%;
}
.content_maxwidth.crm {
	display: flex;
	justify-content: center;
}
.header_logo_area {
	display: flex;
	align-items: center;
	width: 16.5rem;
}
.header_logo_mobile_area {
	display: none;
}
.header_menu {
	display: flex;
	padding-left: 3.13rem;
}
.renew_menutop {
	display: flex;
	justify-content: center;
	align-items: center;
	/* height: 62px; */
	margin-right: 2.37rem;
	font-size: 1.125rem;
	font-weight: 500;
	color: #BEBEBE;
	/* border-radius: 16px; */
	cursor: pointer;
}
.renew_overlay {
	position: fixed;
	top: calc(5rem);
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9; /* 슬라이딩 메뉴보다 아래에 배치 */
}
.menu_top_side {
	position: absolute;
	top: calc(5rem);
	left: calc(100% - 12.06rem);
	transform: translateX(-50%);
	width: 19.375rem;
	height: 28.125rem;
	background: rgba(0,0,0,0.88);
	z-index: 10;
	border-radius: 1rem;
	font-size: 1.25rem;
	font-weight: 500;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.menu_top_sidelist {
	width: 100%;
	height: 100%;
	padding: 0rem 1.5625rem 1.5625rem 1.5625rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.menu_top_side_notdesktop {
	display: none;
}
.selmenu_name {
	display: none;
}
.contents_layout {
	display: flex;
}
.side_menu {
	display: flex;
	flex-direction: column;
	width: 19.625rem;
	height: calc(100% - 5rem);
	z-index: 2;
	/* padding: 3.06rem 3.12rem; */
	padding: 3.06rem 0rem;
	background-color: #F5F5F1;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	overflow-y: auto;
}
.side_menu_catemod {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.renew_menuside {
	/* width: fit-content; */
	width: 13.375rem;
	height: 3rem;
	padding-left: 1.25rem;
	line-height: 3rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	background-color: rgba(234,231,222,0.00);
	color: #878787;
	font-size: 1.0625rem;
	font-weight: 600;
	cursor: pointer;
}
.renew_copyright {
	color: #C8C8C3;
	font-size: 1rem;
	font-weight: 500;
	text-align: center;
	padding-top: 9.375rem;
	padding-bottom: 4rem;
}
.renew_content_title {
	color: #000;
	font-size: 2.75rem;
	font-style: normal;
	font-weight: 600;
}
.renew_content_title.evaempmngreg {
	margin-bottom: 4.5rem;
}
.renew_content_background {
	width: 100%;
	max-width: 76rem;
	min-height: calc(100vh - 12.49rem);
	background: #FFF;
	border-radius: 1rem;
	flex-shrink: 0;
	padding: 4rem 4rem 3rem 4rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.renew_content_background.crm {
	/* max-width: 113.75rem; */
}
.renew_content_background.patientcontent {
	min-height: calc(100vh - 7rem);
}
.renew_dropdown_menu {
	position: absolute;
	width: 15.125rem;
	max-height: 26.5rem;
	margin-top: 0.5rem;
	overflow-y: auto;
	background: rgba(0,0,0,0.88);
	padding: 0.94rem 1.88rem;
	border-radius: 0.5rem;
	z-index: 10;
	font-size: 0.875rem;
	font-weight: 500;
}
.renew_employ_name {
	display: flex;
	color: #272727;
	font-size: 1.25rem;
	font-weight: 600;
}
button.renew_evasignup {
	width: 8.25rem;
	height: 3rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	font-size: 1rem;
	text-align: center;
	font-weight: 500;
	background: #191919;
	color: #FFF;
}
.renew_inner_title {
	color: #626262;
	font-size: 1rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 0.5rem;
}
input.renew_evasignup {
	width: 18.75rem;
	height: 3rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	border: 1px solid #E8E8E8;
	margin-bottom: 2rem;
	padding: 0.875rem;
	color: #191919;
	font-size: 1rem;
	font-weight: 500;
}
input.renew_evasignup:focus {
	border: 1px solid #191919;
}
.renew_save_complete{
	position: fixed;
	top: 20%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* width: 132px; */
	/* height: 46px; */
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.063rem;
	font-weight: 500;
	color: #FFF;
	background-color: #292929;
	border-radius: 50px;
	padding: 0.8125rem 1.5rem;
	z-index: 4;
}
.group_name {
	color: #C89974;
	font-size: 1.125rem;
	font-weight: 600;
}
.category_name {
	font-size: 2.75rem;
	font-style: normal;
	font-weight: 600;
	/* margin-right: 16px; */
}
.content_area {
	margin-top: 4.5rem;
}

/*회원가입*/
.content_area.signup {
	display: flex;
}
.signup_button_area {
	display: flex;
	justify-content: center;
	margin-top: 13.38rem;
}
/*로그인*/
.renew_login_id_pw {
	width: 22.5rem;
	/* height: 80px; */
	padding: 1.25rem 1.5rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	border: 1.2px solid #DEDEDE;
	/* color: #C7C3C3; */
	color: #191919;
	font-size: 1.25rem;
	font-weight: 500;
}
.renew_login_id_pw.loginbutton {
	background: #000;
	color: #FFF;
	font-weight: 600;
	margin-bottom: 2.06rem;
}
.desktop_layout {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 25rem;
	margin-right: 16.15rem;
}
.tablet_layout {
	display: none;
}
.input_area {
	width: 33.75rem;
	height: 25rem;
	flex-shrink: 0;
	background-color: #FFF;
	border-radius: 1.375rem;
	box-shadow: 4px 4px 30.6px 0px rgba(0,0,0,0.05);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.login_between_input {
	height: 1rem;
}
.login_between_button {
	height: 2rem;
}
.option_area {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 22.5rem;
}
img.login_logo {
	width: 21.65994rem;
	height: 8.0625rem;
}
/*약관동의*/
.termsagree_unit_wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.06rem 0.88rem;
}

/*컨텐츠리스트*/
.contentlist_header_area {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 4.25rem;
}
.contentlist_header_title_button_area {
	display: flex;
	align-items: center;
}
.renew_imgvideo_btn {
	width: fit-content;
	border-radius: 3.125rem;
	border: 1px solid #E3E3E3;
	padding: 0.6875rem 1.1875rem;
	align-items: center;
	gap: 0.625rem;
	color: #858585;
	font-size: 0.9375rem;
	font-weight: 600;
	margin-right: 0.5rem;
	cursor: pointer;
}
.renew_imgvideo_btn:hover {
    background: #F3F3F3; /* 호버 시 배경 색상 */
}
.renew_rectangle_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;
	flex-shrink: 0;
	border-radius: 8px;
	border: 1px solid #E3E3E3;
	cursor: pointer;
	/* background: #F2F2EF; */
}
/* 데스크톱에서는 호버 효과 유지 */
@media (hover: hover) and (pointer: fine) {
    .renew_rectangle_btn:hover {
        background: #F3F3F3;
    }
}
/* 모바일 기기에서 터치 시 배경 변경 */
@media (hover: none) and (pointer: coarse) {
    .renew_rectangle_btn:active {
        background: #F3F3F3;
    }
}
.renew_list_item_thumbnail {
	height: 15.625rem;
	flex-shrink: 0;
	border-radius: 0.75rem;
	box-shadow: 9px 4px 42px 4px rgba(0,0,0,0.04);
	cursor: pointer;
}
.renew_list_item_button {
	color: #ADADAD;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
}
.renew_contentlist_searchbox {
	display: inline-flex;
	/* width: 232px; */
	height: 3rem;
	padding: 0.75rem 1.4375rem;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	border-radius: 3.125rem;
	border: 1px solid #F3ECE0;
	background: #FFF;
}
.contents_unit {
	width: 29.4375rem;
	margin-bottom: 4rem;
}
img.category_select {
	display: none;
}
.menu_category {
	display: none;
}

/*드로우페이지*/
.drawpage_content_area {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.drawpage_content_area_mobile {
	display: none;
}
.header_group {
	display: flex;
	width: 87.5rem;
	padding: 1.88rem 0rem 1.25rem 0rem;
	justify-content: space-between;
}
.header_group_test {
	display: flex;
	width: 87.5rem;
	padding: 0.5rem 0rem;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 3;
}
.renew_stroke_sel {
	width: 3.25rem;
	padding: 0.5625rem 0.375rem;
	gap: 0.625rem;
	border-radius: 0.25rem;
	border: 1px solid #454545;
	background: #191919;
	text-align: center;
	color: #FFF;
	font-size: 0.875rem;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;
}
.renew_color_wrap {
	display: flex;
	padding: 9px;
	align-items: flex-start;
	gap: 10px;
	border-radius: 6px;
	background: #252525;
	cursor: pointer;
}
.renew_color_unit {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 0.1875rem;
	border: 1px solid #454545;
}
.renew_back_del {
	display: flex;
	padding: 0.375rem;
	align-items: flex-start;
	gap: 0.625rem;
	border: 1px solid #454545;
	border-radius: 0.375rem;
}
.renew_content_sel {
	background: #000;
	color: #FFF;
	padding: 0rem 1.13rem;
	border-radius: 0.5rem;
	width: 15.125rem;
	height: 3rem;
	flex-shrink: 0;
	font-size: 0.9375rem;
	font-weight: 500;
	cursor: pointer;
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.renew_video_back {
	width: 3rem;
	height: 3rem;
	border-radius: 0.5rem;
	background: #EBECE2;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 1rem;
	flex-shrink: 0;
}
.renew_video_back:hover {
	background: #E5E5DC;
}
.padding_control {
	padding: 0px 260px;
}
.draw_tool_desktop {
	display: inline-flex;
	padding: 0rem 0.5rem;
	align-items: center;
	gap: 0.5rem;
	border-radius: 0.5rem;
	background: #2D2D2D;
}
.draw_tool_tablet {
	display: none;
}
.draw_tool_tablet_test {
	display: none;
}
.draw_background_img {
	display: block;
	width: 100%;
	height: 95vh;
    object-fit: cover;
    object-position: center;
    overflow: hidden;

	/* display: block;
	width: 80%;
	height: auto; */
}
#drawingCanvas {
	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.show_header_button {
	display: flex;
	padding: 0.575rem;
	align-items: flex-start;
	gap: 0.625rem;
	background-color: #454545;
	border-radius: 0.375rem;
	position: absolute;
    top: 0;
    right: 0;
	margin: 0.5rem 2rem;
	z-index: 3;
}

/*담당자 연결*/
button.renew_evamngfront {
	/* width: 3.5rem; */
	/* height: 2.5rem; */
	display: inline-flex;
	padding: 0.6875rem 0.9375rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.5rem;
	font-size: 0.9375rem;
	font-weight: 500;
	color: #A0A0A0;
}
button.renew_evamngfront:hover {
    background: #F0F0F0; /* 호버 시 배경 색상 */
    color: #4E4E4E; /* 호버 시 글자 색상 */
}
.renew_add_employ {
	display: inline-flex;
	align-items: center;
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid #E3E3E3;
	font-size: 1.1625rem;
	font-weight: 600;
	cursor: pointer;
}
.renew_add_employ:hover {
    background: #F3F3F3; /* 호버 시 배경 색상 */
}
.renew_add_employ.patientcontent {
	display: inline-flex;
}
img.renew_employ_img {
	height: 22.5rem;
	border-radius: 0.625rem;
	background: #F8F8F8;
	margin-bottom: 1rem;
}
.renew_diag_btn {
	display: flex;
	padding: 0.75rem;
	align-items: flex-start;
	gap: 0.625rem;
	border-radius: 0.5rem;
	border: 1px solid #363636;
	background: #000;
	cursor: pointer;
}
.renew_diaginfo_div {
	/* width: 593px; */
	height: 1px;
	background: #D3D3D3;
}
.renew_diaginfo_title {
	color: #9F9F9F;
	font-size: 1.063rem;
	font-style: normal;
	font-weight: 600;
	margin: 0.875rem 0rem;
}
.diagbutton_group {
	display: flex;
	width: 20rem;
	height: 4rem;
	padding: 0.5rem 0.5rem 0.5rem 0.4375rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.5rem;
	background: #292929;
	margin-right: 10.94rem;
}
.evamanagefront_name {
	width: 22.5rem;
}
.evamanagefront_birth {
	width: 7.5rem;
}
.evamanagefront_phone {
	width: 8.125rem;
}
.evamanagefront_seldel {
	display: flex;
}
.evamanagefront_seldel_mobile {
	display: none;
}
.evamanageback_footer_area {
	display: flex;
	justify-content: space-between;
}
.evamanageback_footer_info_area {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	max-width: 37.06rem;
}
.evamanageback_image_area {
	width: 15rem;
	margin-right: 2rem;
	margin-bottom: 1.875rem;
}

/*고객만족도 조사*/
.renew_question {
	display: flex;
	color: #000;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	margin-bottom: 1.5rem;
}
.renew_question_multiple {
	color: #858585;
	font-size: 1.063rem;
	font-weight: 400;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-right: 4rem;
}
.flex_direction {
	display: flex;
}
.evasurvey_image_area {
	margin-right: 7.125rem;
	margin-bottom: 4.5rem;
}
.evasurvey_multiple {
	display: flex;
	margin-bottom: 3.5rem;
}
.evasurvey_textarea {
	width: 36.1875rem;
	height: 6.9375rem;
	border-radius: 0.5rem;
	background: #F4F4F0;
	padding: 0.625rem;
	resize: none;
	margin-bottom: 3.5rem;
}

/*문항 관리*/
.renew_question_empdiv {
	color: #BEBEBE;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	cursor: pointer;
}
button.renew_question_moddel {
	display: inline-flex;
	padding: 0.6875rem 0.9375rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.5rem;
	color: #A0A0A0;
	font-size: 0.9375rem;
	font-weight: 500;	
}
button.renew_question_moddel:hover {
    background: #F0F0F0; /* 호버 시 배경 색상 */
    color: #4E4E4E; /* 호버 시 글자 색상 */
}
.evaquestionmanage_button_area {
	display: flex;
}
.evaquestionmanage_button_area_mobile {
	display: none;
}

/*문항 편집*/
.renew_question_mod_subtitle {
	color: #626262;
	font-size: 1.125rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
}
.renew_question_sel_unit {
	display: flex;
	height: 3rem;
	padding: 0.6875rem 1rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.5rem;
	border: 1px solid #E3E3E3;
	cursor: pointer;
	font-size: 0.9375rem;
	font-weight: 500;
	color: #9F9F9F;
}
button.renew_question_mod {
	width: 8.25rem;
	height: 3rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	background: #EFEFEF;
	color: #373737;
	text-align: center;
	font-size: 1rem;
	font-weight: 500;
}
button.renew_question_mod:hover {
	background: #E4E4E4;
}

/*직원 편집*/
input.renew_employee_mod {
	width: 18.75rem;
	height: 3rem;
	padding-left: 0.875rem;
	border-radius: 0.5rem;
	border: 1px solid #E8E8E8;
	color: #ABABAB;
	font-size: 1rem;
	font-weight: 500;
}
.evaempmngreg_flex {
	display: flex;
	align-items: center;
}

/*리포트*/
.renew_report_button {
	/* border-radius: 6px; */
	padding-bottom: 0.3125rem;
	height: 2.063rem;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #BEBEBE;
	font-size: 1.25rem;
	font-weight: 500;
	cursor: pointer;
}
.renew_report_patcnt {
	color: #C39F56;
	font-size: 1rem;
	font-weight: 600;
}
.renew_report_number {
	color: #272727;
	font-size: 2.5rem;
	font-weight: 600;
}
.renew_report_number_text {
	color: #858585;
	font-size: 1.25rem;
	font-weight: 500;
	padding: 0rem 0rem 0.3125rem 0.3125rem;
}
.renew_report_question_div {
	color: #BEBEBE;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	cursor: pointer;
	border-bottom: 2px #E3E3E3 solid;
}
.sliding_calendar {
	position: absolute; 
	background-color: white;
	border: 1px solid #E5E8EB;
	z-index: 100;
	display: none;
}
.sliding_calendar.show {
	display: block;
}
.calendar_button {
	display: flex;
	width: 20.9375rem;
	height: 3.375rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	background: #F4F4F4;
	align-items: center;
	justify-content: center;
}
.calendar_button:hover {
	background-color: #E4E4E4;
}
.evareporthome_image_area {
	width: 15rem;
	cursor: pointer;
}
.evareporthome_image {
	width: 100%;
	height: 22.5rem;
	border-radius: 0.625rem;
}
.evareportshow_flex {
	display: flex;
	margin-bottom: 3.125rem;
}
.evareportshow_text_area {
	margin-left: 4.5rem;
}
.evareportshow_nametag {
	display: flex;
	width: 7rem;
	height: 2.5rem;
	border: 0.375rem;
	background: #F5F5F5;
	align-items: center;
	justify-content: center;
	color: #272727;
	font-size: 1.25rem;
	font-weight: 600;
	border-radius: 0.375rem 0.4375rem;
}
.evareportshow_performance_area {
	display: flex;
	margin-top: 5.438rem;
	margin-bottom: 3rem;
}
.evareportshow_point_area {
	display: flex;
	margin-top: 1.5rem;
	margin-bottom: 3.5rem;
	color: #858585;
	font-size: 1.063rem;
	font-weight: 400;
}
.evareportshow_point_flex {
	display: flex;
}
.evareportshow_point {
	margin-left: 0.5rem;
	margin-right: 4rem;
	color: #2D2D2D;
	font-weight: 600;
}
.evareportterm_button_flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;
	margin-top: 4.5rem;
}
.evareportterm_empsel_area {
	display: flex;
	align-items: center;
}
.evareportterm_name {
	width: 10%;
}
.evareportterm_diagdatalength {
	width: 15%;
}
.evareportterm_totaldiagtime {
	width: 20%;
}
.evareportterm_avgdiagtime {
	width: 15%;
}
.evareportterm_pointdatalength {
	width: 10%;
}
.evareportterm_pointtotal {
	width: 10%;
}
.evareportterm_pointave {
	width: 10%;
}
.evareportterm_commentcnt {
	width: 10%;
}
.evareportterm_inner_container_header {
	display: flex;
	color: #AEAEAE;
	font-size: 1rem;
	font-weight: 500;
	padding-bottom: 0.8125rem;
	border-bottom: 1px #E8E8E8 solid;
}
.evareportterm_inner_container_body {
	display: flex;
	color: #373737;
	font-size: 1.125rem;
	font-weight: 600;
	height: 3.5rem;
	border-bottom: 1px #E8E8E8 solid;
	align-items: center;
}
/*Q&A*/
.renew_qna_read_title {
	color: #9F9F9F;
	font-size: 0.875rem;
	font-weight: 500;
}
.renew_qna_read_content {
	color: #373737;
	font-size: 1.063rem;
	font-weight: 500;
	margin-top: 0.3125rem;
}

/*CRM*/
.renew_crm_unit_date {
	color: #7962F6;
	font-size: 1.25rem;
	font-weight: 600;
}
.renew_crm_dash_between {
	width: 1px;
	height: 50px;
	background: #E8E8E8;
}
.renew_crm_unit_title {
	color: #7962F6;
	font-size: 1.25rem;
	font-weight: 600;
	background: #F0F0F0;
	border-radius: 4px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.3125rem 0.625rem;
}
.renew_crm_checklist_header {
	display: flex;
	padding: 0.8125rem 0rem;
	border-bottom: 1px #E8E8E8 solid;
	color: #AEAEAE;
	font-size: 1rem;
	font-weight: 500;
}
.renew_crm_noti_dash_header {
	color: #AAA;
	font-size: 0.8125rem;
	font-weight: 600;
}
.renew_crm_noti_dash_content {
	color: #7962F6;
	font-size: 1rem;
	font-weight: 600;
}
.renew_crm_noti_subtitle {
	color: #272727;
	font-size: 1.25rem;
	font-weight: 600;
	margin-top: 3.125rem;
	margin-bottom: 1.5rem;
}
.renew_crm_noti_step_group{
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 1.875rem;
}
.renew_crm_noti_step_complete{
	width: 6.5rem;
	height: 2.875rem;
	border-radius: 3.125rem;
	background: #292929;
	display: flex;
	align-items: center;
	justify-content: center;
}
.renew_crm_noti_step_wrap {
	display: inline-flex;
	flex-direction: column;
	border-radius: 1.25rem;
	background: #F4F4F4;
	padding: 1.125rem 1.75rem 1.625rem 1.75rem;
	width: 13.94rem;
	height: 15rem;
	margin-top: 1.875rem;
}
.renew_crm_noti_step_title {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.1875rem 0.4375rem;
	gap: 0.625rem;
	flex-shrink: 0;
	border-radius: 4px;
	background: #E3E3E3;
	color: #7E7E7E;
	font-size: 0.875rem;
	font-weight: 500;
	width: 3.125rem;
	height: 1.4375rem;
}
.renew_crm_noti_step_field {
	color: #A3A3A3;
	font-size: 0.875rem;
	font-weight: 500;
	margin-top: 1.125rem;
	margin-bottom: 0.375rem;
}
.renew_crm_noti_step_attribute {
	width: 10.44rem;
	padding: 0.8125rem 1.188rem;
	border-radius: 3.125rem;
	border: 1px solid #E8E8E8;
	background: #FFF;
	display: flex;
	align-items: center;
}
.renew_crm_noti_step_valdate {
	width: 100%;
	color: #373737;
	font-size: 1rem;
	font-weight: 500;
}
.renew_crm_noti_checklist_header {
	color: #E9E9E9;
	font-size: 1rem;
	font-weight: 600;
}
.renew_crm_noti_checklist_body {
	display: flex;
	width: 9.375rem;
	height: 3.188rem;
	padding: 0.9375rem 1.75rem;
	justify-content: center;
	align-items: center;
	color: #FFF;
	font-size: 1.125rem;
	font-weight: 600;
	background: #000;
	border-radius: 0.5rem;
	border: 1px solid #363636;
	margin-top: 1rem;
}
.renew_crm_main_attribute_input {
	padding: 10px;
	color: #373737;
	font-size: 15px;
	font-weight: 500;
	border-right: 1px #E8E8E8 solid;
}
.renew_crm_main_button {
	display: inline-flex;
	align-items: center;
	padding: 0.75rem;
	border-radius: 0.5rem;
	border: 1px solid #E3E3E3;
	color: #373737;
	font-size: 1.063rem;
	font-weight: 600;
	cursor: pointer;
}
.renew_crm_main_button:hover {
    background: #E3E3E3; /* 호버 시 배경 색상 */
}
.sliding_calendar_regdate {
	position: fixed;
	background-color: white;
	border: 1px solid #E5E8EB;
	z-index: 100;
}
.crm_contact_background {
	background: #FFF;
	width: 100%;
	max-width: 113.75rem;
	padding: 4rem 2.5rem;
	border-radius: 1rem;
	min-height: calc(100vh - 12.49rem);
}
.crm_searchbox {
	display: inline-flex;
	width: 10.125rem;
	height: 2.75rem;
	padding: 0.75rem 1.25rem;
	align-items: center;
	justify-content: space-between;
	flex-shrink: 0;
	border-radius: 3.125rem;
	border: 1px solid #F3ECE0;
	background: #FFF;
}
.crm_checklist_wrap {
	display: flex;
	justify-content: center;
	overflow: auto;
}
.crmnoticenter_notice_area {
	border-radius: 0.5rem;
	background: #F8F8F8;
	width: 55rem;
	height: 7.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #272727;
	font-size: 1.063rem;
	font-weight: 600;
	padding: 0rem 5.625rem;
}
.crmnoticenter_notisection_area {
	margin-top: 4rem;
}
.crm_checklist_header_name {
	width: 20%;
}
.crm_checklist_header_phone {
	width: 20%;
}
.crm_checklist_header_age {
	width: 10%;
}
.crm_checklist_header_carecate {
	width: 20%;
}
.crm_checklist_header_steptext {
	width: 10%;
}
.crm_checklist_header_status {
	width: 10%;
}
.crm_checklist_header_phonesms {
	width: 10%;
}
.crmnoticenter_survey_unit_flex {
	display: flex;
}
.crmnoticenter_survey_unit_name {
	color: #373737;
	font-size: 0.9375rem;
	font-weight: 600;
	margin-left: 0.9375rem;
}
.crmmain_background {
	background: #FFF;
	padding: 4rem 2.5rem;
	border-radius: 1rem;
	width: 95vw; /* 브라우저 너비의 100%를 차지 */
    max-width: 100%; /* 부모 요소보다 커지지 않도록 제한 */
	height: calc(100vh - 12.49rem);
}
.crmmain_infotext {
	color: #858585;
	font-size: 0.8125rem;
	font-weight: 500;
}
.crmmain_infotext.mobile {
	display: none;
}
.crmmain_content_area {
	overflow: auto;
	max-width: 100%;
	height: calc(100vh - 27.62rem);
}
.crmcontact_personinfo_area {
	display: inline-flex;
	align-items: center;
	background: #F4F4F4;
	padding: 1.688rem 1.125rem;
	border-radius: 0.375rem;
}
.crmcontact_personname {
	display: flex;
	color: #191919;
	font-size: 1.75rem;
	font-weight: 600;
}
.crmcontact_personname.age {
	color: #858585;
	margin-left: 0.4375rem;
}

/*카테고리 편집*/
button.renew_categorymod {
	width: 8.25rem;
	height: 3rem;
	border-radius: 0.5rem;
	background: #EFEFEF;
	color: #373737;
	font-size: 1rem;
	text-align: center;
	font-weight: 500;
}
button.renew_categorymod:hover {
	background: #E4E4E4;
}

/*컨텐츠 업로드*/
.contents_upload_flex {
	display: flex;
	align-items: center;
}
.contentupload_image_area {
	width: 37.5rem;
	height: 22.5rem;
	flex-shrink: 0;
	border-radius: 0.625rem;
	background: #F8F8F8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #A5A5A5;
	font-size: 1rem;
	font-weight: 400;
	margin-right: 5.44rem;
}
.contentupload_area {
	width: 37.5rem;
	height: 22.5rem;
	margin-right: 5.44rem;
}
.contentupload_area.image {
	border-radius: 0.625rem;
}
/*게시판*/
.notice_header {
	display: flex;
	color: #9F9F9F;
	font-size: 1rem;
	font-weight: 500;
	border-bottom: 1px solid #E8E8E8;
	padding-bottom: 0.625rem;
}
.notice_header_index {
	width: 10%;
}
.notice_header_username {
	width: 20%;
}
.notice_header_title {
	width: 50%;
}
.notice_header_info {
	width: 20%;
}
.notice_header_info.body {
	display: flex;
	justify-content: space-between;
	font-size: 0.9375rem;
	font-weight: 500;
	color: #858585;
	align-items: center;
}
.notice_header_mobile_count {
	display: none;
}
.notice_header_mobile_content {
	display: none;
}
.noticeread_info_area {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 0.625rem;
	background: #F9F9F9;
	padding: 1.125rem 1.5rem;
}
.noticeread_info_area.mobile {
	display: none;
}
.noticeread_content {
	padding: 1.5rem;
	color: #707070;
	font-size: 0.9375rem;
	font-weight: 500;
	margin-bottom: 1.5rem;
	line-height: 1.4375rem;
	white-space: pre-wrap;
}
.noticeread_moddel {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 1.25rem;
	padding: 0rem 1.5rem;
}
.noticeread_reply_area {
	border-top: 1px #E8E8E8 dashed;
	padding: 0.875rem 1.5rem;
}
.bottom_button_area {
	display: flex;
	justify-content: center;
	margin-top: 6.25rem;
}
.noticeread_noticetitle_area {
	display: flex;
	align-items: center;
	margin-bottom: 4.5rem;
}
.floating_icon {
	display: none; /* 기본적으로 아이콘을 숨깁니다 */
	position: fixed;
	bottom: 3.12rem;
  	right: 1.87rem;
	z-index: 1000; /* 다른 요소들 위에 표시되도록 합니다 */
	cursor: pointer;
}

/* ========== 미디어쿼리 태블릿======== */
@media (max-width: 1400px) {
	.renew_background.drawvideo {
		padding-top: 0rem;
	}
	.content_align.header {
		max-width: 59.06rem;
	}
	.content_maxwidth {
		max-width: 59.06rem;
	}
	.header_logo_mobile_area {
		display: block;
	}
	.header_logo_area {
		display: none;
	}
	.header_menu {
		display: none;
	}
	.menu_top_side {
		/* left: calc(100% - 16rem); */
		left: calc(50% + 16.7rem);
		width: 25.625rem;
		height: 28.125rem;
	}
	.menu_top_side_notdesktop {
		display: block;
		margin-top: 2.0625rem;
		cursor: pointer;
	}
	.selmenu_name {
		display: block;
		color: #565656;
		font-size: 1.25rem;
		font-weight: 600;
		white-space: nowrap;
	}
	.contents_layout {
		flex-direction: column;
	}
	.side_menu {
		display: none;
	}
	.side_menu_wrap_tablet {
		z-index: 2;
		display: flex;
		justify-content: center;
	}
	.side_menu.eva {
		z-index: 2;
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		width: calc(100% - 6.24rem);
		max-width: 59.06rem;
		height: 5.75rem;
		align-items: center;
		flex-direction: row;
		padding: 0rem;
		background: #FAFAF8;
	}
	.side_menu_list {
		display: flex;
	}
	.renew_menuside {
		width: fit-content;
		padding: 1.3125rem 1rem;
		display: flex;
		align-items: center;
		border-radius: 0.375rem;
		border: 1px solid #ECECEC;
		margin-right: 0.625rem;
		font-size: 1.0625rem;
		font-weight: 600;
	}
	.flex_direction {
		flex-direction: column;
	}
	.header_group {
		justify-content: center;
	}
	.header_group_test {
		justify-content: center;
	}
	.padding_control {
		padding: 0px 40px;
	}
	.contents_unit {
		width: 48.5%;
		margin-bottom: 2rem;
	}
	.contents_upload_flex {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.contents_upload_option {
		margin-top: 3.125rem;
	}
	.desktop_layout {
		display: none;
	}
	.group_name {
		display: none;
	}
	.renew_contentlist_searchbox {
		padding: 0.625rem 1.4375rem;
		flex-shrink: 0;
	}
	img.category_select {
		display: block;
		width: 22px;
		height: 22px;
		margin-left: 8px;
		cursor: pointer;
	}
	.menu_category {
		position: absolute;
		top: 12rem;
		left: calc(50% - 20.53rem);
		/* left: 12rem; */
		transform: translateX(-50%);
		box-shadow: 9px 4px 42px 4px rgba(0,0,0,0.04);
		width: 18rem;
		height: 30rem;
		background: #FFF;
		z-index: 10;
		border-radius: 10px;
		font-size: 1.375rem;
		font-weight: 600;
		color: #BEBEBE;
		display: flex;
		flex-direction: column;
		padding: 1.25rem;
		overflow-y: auto;
	}
	.draw_tool_desktop {
		display: none;
	}
	.draw_tool_tablet {
		display: flex;
		justify-content: center;
		margin-bottom: 0.5rem;
		/* margin-top: 1.25rem; */
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 3;
	}
	.draw_tool_tablet_test {
		display: flex;
		justify-content: center;
		margin-bottom: 0.5rem;
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 3;
	}
	.draw_background_img {
		height: 90vh;
	}
	/*공통*/
	.renew_content_title {
		font-size: 2.625rem;
	}
	.renew_content_background.eva {
		margin-top: 3.2rem;
		min-height: calc(100vh - 15.125rem);
	}
	/*로그인*/
	img.login_logo {
		width: 13.94206rem;
		height: 5.18969rem;
		flex-shrink: 0;
	}
	.input_area {
		width: 37.5rem;
		height: 26.0625rem;
	}
	.renew_login_id_pw {
		width: 22.5rem;
		padding: 1.375rem 1.5rem;
		font-size: 1.375rem;
	}
	.option_area {
		width: 22.5rem;
	}
	.tablet_layout {
		display: block;
		width: 37.5rem;
		margin-top: 19.62rem;
		margin-bottom: 2.02rem;
	}
	/*담당자연결*/
	.diagbutton_group {
		margin-right: 4rem;
	}
	/*CRM*/
	.crm_checklist_wrap {
		/* display: flex; */
		justify-content: flex-start;
		/* overflow: auto; */
	}
	/*게시판*/
	.renew_content_background.notice {
		max-width: 59.06rem;
	}
}

/* ========== 미디어쿼리 모바일======== */
@media (max-width: 480px) {
	/*공통*/
	.renew_background {
		padding: 0rem;
	}
	.renew_background.contentlist {
		padding: 2.5rem 1.87rem;
	}
	.renew_background.header {
		padding: 0rem 1.875rem;
	}
	.renew_background.notice {
		padding: 0rem;
	}
	.renew_background.signup {
		padding: 1rem 0rem 0rem 0rem;
	}
	.renew_content_background {
		/* margin-top: 4.5rem; */
		margin-top: 1rem;
		padding: 2.5rem 1.88rem 2.5rem 1.88rem;
		min-height: calc(100vh - 8.62rem);
		margin-bottom: 3.12rem;
	}
	.renew_content_background.eva {
		margin-top: 6.5rem;
	}
	.renew_content_background.crm {
		margin-top: 1rem;
	}
	.renew_content_background.notice {
		margin-top: 1rem;
	}
	.renew_content_title {
		font-size: 2.25rem;
	}
	.selmenu_name {
		font-size: 1.25rem;
	}
	.side_menu.eva {
		width: 100%;
		padding-left: 1.87rem;
	}
	button.renew_evasignup {
		width: 10rem;
	}
	.menu_top_side {
		/* left: 12.8125rem; */
		left: calc(100% - 14.6825rem);
	}
	/*회원가입*/
	.content_area.signup {
		flex-direction: column;
		margin-top: 0rem;
	}
	.signup_button_area {
		margin-top: 3.38rem;
	}
	/*로그인*/
	.tablet_layout {
		display: flex;
		justify-content: center;
		width: 26rem;
		margin-top: 14.31rem;
		margin-bottom: 4.39rem;
	}
	img.login_logo {
		width: 14rem;
		height: 5.2rem;
		flex-shrink: 0;
		margin-top: 8rem;
	}
	.input_area {
		width: 26.25rem;
		height: 21.125rem;
		flex-shrink: 0;
		border-radius: 1.2375rem;
	}
	.login_between_input {
		height: 0.75rem;
	}
	.login_between_button {
		height: 1.5rem;
	}
	.renew_login_id_pw {
		width: 18.75rem;
		height: 3.5rem;
		padding: 0.9375rem 1.5rem;
		font-size: 1.125rem;
	}
	.renew_login_id_pw.loginbutton {
		margin-bottom: 1.94rem;
	}
	.option_area {
		width: 18.75rem;
	}
	/*약관동의*/
	.termsagree_unit_wrap {
		padding: 1.06rem 0rem;
	}
	/*컨텐츠리스트*/
	.contentlist_header_area {
		flex-direction: column;
		margin-bottom: 1.19rem;
	}
	.contentlist_header_title_button_area {
		width: 100%;
		justify-content: space-between;
		margin-bottom: 3.12rem;
	}
	.contentlist_header_searchbox_area {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.contents_unit {
		width: 100%;
	}
	.menu_category {
		left: calc(50%);
		width: calc(100% - 3.74rem);
		height: 20rem;
	}
	/*드로우페이지*/
	.drawpage_content_area {
		display: none;
	}
	.drawpage_content_area_mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #A5A5A5;
		font-size: 1.0625rem;
		font-weight: 600;
		line-height: 1.4375rem;
	}
	/*담당자 연결*/
	.evamanagefront_name {
		width: 9.3125rem;
	}
	.evamanagefront_birth {
		width: 6.12rem;
	}
	.evamanagefront_phone {
		width: 8rem;
	}
	.evamanagefront_seldel {
		display: none;
	}
	.evamanagefront_seldel_mobile {
		display: block;
	}
	.evamanagefront_seldel_button {
		display: flex;
		width: 5rem;
		padding: 0.6875rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 0.625rem 0.625rem 0rem 0rem;
		background: #FFF;
		color: #656565;
		font-size: 1.125rem;
		font-weight: 600;
		line-height: normal;
	}
	.evamanagefront_seldel_button.del {
		color: #ff594f;
	}
	.evamanageback_footer_area {
		flex-direction: column;
		align-items: center;
	}
	.diagbutton_group {
		margin-right: 0rem;
		margin-bottom: 2rem;
	}
	.evamanageback_footer_info_area {
		width: 100%;
	}
	.evamanageback_image_area {
		width: 12rem;
	}
	img.renew_employ_img {
		height: 18rem;
	}
	.renew_add_employ.patientcontent {
		display: none;
	}
	/*고객만족도 조사*/
	.flex_direction {
		align-items: center;
	}
	.evasurvey_image_area {
		margin-right: 0rem;
	}
	.renew_question {
		font-size: 1.0625rem;
		margin-bottom: 1rem;
	}
	.renew_question_multiple {
		font-size: 1rem;
		gap: 0.5rem;
		margin-right: 1.69rem;
	}
	.evasurvey_multiple {
		margin-bottom: 2.19rem;
	}
	.evasurvey_textarea {
		width: 100%;
		margin-bottom: 2.19rem;
	}
	/*문항 관리*/
	.evaquestionmanage_button_area {
		display: none;
	}
	.evaquestionmanage_button_area_mobile {
		display: block;
	}
	/*컨텐츠 업로드*/
	.contentupload_image_area {
		width: 100%;
	}
	.contentupload_area {
		width: 100%;
	}
	/*직원 편집*/
	.evaempmngreg_flex {
		align-items: flex-start;
		flex-direction: column;
	}
	.renew_content_title.evaempmngreg {
		margin-bottom: 3.12rem;
	}
	.evaempmngreg_margin {
		margin-top: 1.88rem;
	}
	/*리포트*/
	.evareporthome_image_area {
		width: 47%;
	}
	.evareportshow_flex {
		flex-direction: column;
		align-items: center;
	}
	.evareportshow_text_area {
		margin-left: 0rem;
		margin-top: 0.62rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.evareportshow_performance_area {
		margin-top: 3.62rem;
		margin-bottom: 1.5rem;
	}
	.renew_report_patcnt {
		margin-bottom: 0.75rem;
	}
	.renew_report_number {
		font-size: 1.625rem;
	}
	.renew_report_question_div {
		width: 50%;
		text-align: center;
		border-bottom: 2px #E3E3E3 solid;
	}
	.evareportshow_point_area {
		justify-content: space-between;
	}
	.evareportshow_point_flex {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.evareportshow_point {
		margin-left: 0rem;
		margin-right: 0rem;
	}
	.evareportterm_button_flex {
		flex-direction: column;
		margin-top: 3.12rem;
	}
	.evareportterm_empsel_area {
		justify-content: flex-end;
		width: 100%;
		margin-top: 2.19rem;
	}
	.evareportterm_name {
		width: 6.13rem;
		flex-shrink: 0;
	}
	.evareportterm_diagdatalength {
		width: 6.13rem;
		flex-shrink: 0;
	}
	.evareportterm_totaldiagtime {
		width: 8rem;
		flex-shrink: 0;
	}
	.evareportterm_avgdiagtime {
		width: 8rem;
		flex-shrink: 0;
	}
	.evareportterm_pointdatalength {
		width: 6.13rem;
		flex-shrink: 0;
	}
	.evareportterm_pointtotal {
		width: 6.13rem;
		flex-shrink: 0;
	}
	.evareportterm_pointave {
		width: 6.13rem;
		flex-shrink: 0;
	}
	.evareportterm_commentcnt {
		width: 6.13rem;
		flex-shrink: 0;
	}
	.evareportterm_inner_container_header {
		width: max-content;
	}
	.evareportterm_inner_container_body {
		width: max-content;
	}
	/*CRM*/
	.crmnoticenter_notice_area {	
		width: 100%;
		height: 6.375rem;
		font-size: 0.875rem;
		padding: 0rem 1.19rem;
	}
	.crmnoticenter_notisection_area {
		margin-top: 3rem;
	}
	.renew_crm_unit_title {
		margin-bottom: 1.5rem;
	}
	.crm_checklist_header_name {
		width: 20%;
		cursor: pointer;
	}
	.crm_checklist_header_phone {
		width: 30%;
	}
	.crm_checklist_header_age {
		display: none;
	}
	.crm_checklist_header_carecate {
		width: 30%;
	}
	.crm_checklist_header_steptext {
		display: none;
	}
	.crm_checklist_header_status {
		display: none;
	}
	.crm_checklist_header_phonesms {
		width: 20%;
	}
	.crmnoticenter_survey_unit_flex {
		flex-direction: column;
	}
	.crmnoticenter_survey_unit_name {
		margin-left: 0rem;
	}
	.crmmain_background {
		margin-top: 1rem;
		padding: 2.5rem 1.87rem;
		width: 100%;
		height: calc(100vh - 8.62rem);
	}
	.crmmain_infotext.web {
		display: none;
	}
	.crmmain_infotext.mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
	}
	.crmmain_content_area {
		height: calc(100vh - 24.43rem);
	}
	.crm_contact_background {
		margin-top: 1rem;
		margin-bottom: 3.12rem;
		min-height: calc(100vh - 8.62rem);
	}
	.crmcontact_personinfo_area {
		padding: 1.75rem 1rem;
	}
	.crmcontact_personname {
		font-size: 1.25rem;
	}
	.renew_crm_noti_dash_content {
		margin-top: 0.44rem;
	}
	.renew_crm_noti_subtitle {
		margin-top: 3.125rem;
		margin-bottom: 1.5rem;
	}
	/*게시판*/
	.notice_header_index {
		display: none;
	}
	.notice_header_username {
		display: none;
	}
	.notice_header_title {
		display: none;
	}
	.notice_header_info {
		display: none;
	}
	.notice_header_info.body {
		display: none;
	}
	.notice_header_mobile_count {
		display: flex;
		justify-content: center;
		width: 15%;
	}
	.notice_header_mobile_content {
		display: block;
		width: 85%;
		cursor: pointer;
	}
	.noticeread_info_area {
		display: none;
	}
	.noticeread_info_area.mobile {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		background: #FFF;
		padding: 0rem;
	}
	.noticeread_content {
		padding: 3rem 0rem;
		font-size: 1.1rem;
		line-height: 1.6rem;
	}
	.noticeread_moddel {
		padding: 0rem;
	}
	.noticeread_reply_area {
		padding: 0.875rem 0rem;
	}
	.bottom_button_area {
		margin-top: 12rem;
	}
	.noticeread_noticetitle_area {
		margin-bottom: 2.5rem;
	}
	/*문항 편집*/
	.renew_question_sel_unit {
		font-size: 1.1rem;
	}
	/*플로팅아이콘*/
	.floating_icon {
		display: block;
	}
	.floating_icon img {
		width: 4.5rem; /* 아이콘의 크기를 조절할 수 있습니다 */
		height: auto;
	}
}